import { readonly, ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'

export default () => {
  // data
  let records = ref([])
  let organisations = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()

  const fields = ref([
    {
      label: 'Survey ID',
      key: 'id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Organisation',
      key: 'auth0_org_name',
      type: 'string',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'string',
    },
    {
      label: 'name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'address',
      key: 'address',
      type: 'string',
    },
    {
      label: 'zip',
      key: 'zip',
      type: 'string',
    },
    {
      label: 'place',
      key: 'place',
      type: 'string',
    },
    {
      label: 'Export count',
      key: 'export_count',
      type: 'number',
    },
    {
      label: 'Edit count',
      key: 'edit_count',
      type: 'number',
    },
    {
      label: 'Date created',
      key: 'date_created',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'Date edited',
      key: 'date_edited',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'First export date',
      key: 'export_date_first',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'Last export date',
      key: 'export_date_last',
      type: 'date',
      add: false,
      edit: false,
    },
  ])

  const organisationFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
    },
  ])

  // list
  async function getRecords() {
    loadHandler.setLoadingState('get_records', true)

    const requestFields = [
      'id',
      'description',
      'name',
      'address',
      'zip',
      'place',
      'export_count',
      'edit_count',
      'date_created',
      'date_edited',
      'export_date_first',
      'export_date_last',
      'auth0_org_id',
    ]

    const fieldsParam = '?fields=' + requestFields.join(',')

    const [surveysResponse, organisationsResponse] = await Promise.all([
      useApi.request('get', `admin/surveys${fieldsParam}`),
      useApi.request('get', `admin/organizations`),
    ])

    loadHandler.setLoadingState('get_records', false)

    const surveyData = surveysResponse.data
    const organisationData = organisationsResponse.data

    // Create a mapping of auth0_org_id to organization name
    const orgMap = organisationData.reduce((acc, org) => {
      acc[org.auth0_org_id] = org.name
      return acc
    }, {})

    // Add organization name to each survey record
    const combinedData = surveyData.map((survey) => ({
      ...survey,
      auth0_org_name: orgMap[survey.auth0_org_id] || 'Unknown Organization',
    }))

    // set surveydata
    records.value = combinedData

    // set org data
    organisations.value = organisationData
  }

  // exposed
  return {
    fields: readonly(fields),
    records: readonly(records),
    getRecords,
    loadHandler,
    showAdd,
    showEdit,
    organisations,
    organisationFields,
  }
}
