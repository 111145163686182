<template>
  <label class="inline-flex items-center cursor-pointer">
    <div class="relative flex items-center">
      <input type="checkbox" :checked="modelValue" @change="updateValue" class="sr-only" :disabled="disabled" />
      <div
        class="w-5 h-5 border-2 rounded flex items-center justify-center"
        :class="{
          'border-sky-600 bg-sky-700': modelValue,
          'border-gray-300 bg-white': !modelValue,
          'opacity-50 cursor-not-allowed': disabled,
        }"
      >
        <svg
          class="w-3 h-3 text-white"
          :class="{ 'opacity-0': !modelValue }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <span v-if="label" class="ml-2 text-sm text-gray-700">{{ label }}</span>
    </div>
  </label>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (event) => {
  if (!props.disabled) {
    emit('update:modelValue', event.target.checked)
  }
}
</script>
