<template>
  <div class="flex flex-wrap gap-2">
    <div v-if="items.length > 0" class="cursor-pointer relative group">
      <div class="flex gap-2 border-dashed border-b border-slate-400">
        {{ items.length }}
        <featherIcon class="w-4 h-4 mr-2 my-auto" icon="TagIcon" />
      </div>

      <!-- Hover popup -->
      <div
        class="invisible group-hover:visible absolute left-0 top-full mt-2 p-2 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap border"
      >
        <div class="flex flex-wrap gap-2">
          <base-pill v-for="item in items" :key="item" variant="secondary" :class="getTagColor(item)">
            {{ item }}
          </base-pill>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const DEFAULT_COLORS = [
  'bg-blue-500',
  'bg-purple-500',
  'bg-pink-500',
  'bg-indigo-500',
  'bg-orange-500',
  'bg-cyan-500',
  'bg-teal-500',
  'bg-emerald-500',
]

const DEFAULT_SPECIAL_MAPPINGS = {
  electricity: 'bg-[#ffcc00]',
  gas: 'bg-[#aecc53]',
  water: 'bg-[#94cef2]',
  heat: 'bg-[#ed6a5a]',
  heatcost: 'bg-[#93748a]',
}

defineProps({
  items: Array,
})

const getTagColor = (name) => {
  const lowercaseName = name.toLowerCase()
  if (DEFAULT_SPECIAL_MAPPINGS[lowercaseName]) {
    return DEFAULT_SPECIAL_MAPPINGS[lowercaseName]
  }

  // Generate consistent color based on name
  const hash = Array.from(name).reduce((acc, char) => (acc << 5) - acc + char.charCodeAt(0), 0) & 0xffffffff

  return DEFAULT_COLORS[Math.abs(hash) % DEFAULT_COLORS.length]
}
</script>
