<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col overflow-y-auto">
        <!-- title -->
        <div>
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <baseBackButton />
              </span>

              <basePageTitle title="Admin" />
            </div>
          </div>
        </div>

        <!-- content columns -->
        <div class="flex flex-row min-h-0">
          <div class="py-10 basis-full">
            <!-- tabs -->
            <baseTabs :handler="tabs" :loading="useSurveyAdmin.loadHandler.somethingLoading.value">
              <template v-slot:panel-opnameoverzicht>
                <!-- survey overview -->
                <data-table :data="useSurveyAdmin.records" :loading="useSurveyAdmin.loadHandler.somethingLoading.value">
                  <template #toolbar>
                    <table-filters-button />
                    <table-export-button @export="onExport" :loading="useExportExcel.loadHandler.states.export_excel" />
                    <search-field />
                  </template>

                  <template #header>
                    <table-header-cell field="auth0_org_name" label="Organisatie">Organisatie</table-header-cell>
                    <table-header-cell field="description" label="Omschrijving">Omschrijving</table-header-cell>
                    <table-header-cell field="name" label="Naam">Naam</table-header-cell>
                    <table-header-cell field="address" label="Adres">Adres</table-header-cell>
                    <table-header-cell field="zip" label="Postcode">Postcode</table-header-cell>
                    <table-header-cell field="place" label="Plaats">Plaats</table-header-cell>
                    <table-header-cell field="export_count" label="Aantal exports">Aantal exports</table-header-cell>
                    <table-header-cell field="edit_count" label="Aantal bewerkingen"
                      >Aantal bewerkingen</table-header-cell
                    >
                    <table-header-cell field="date_created" label="Datum aangemaakt"
                      >Datum aangemaakt</table-header-cell
                    >
                    <table-header-cell field="date_edited" label="Datum bewerkt">Datum bewerkt</table-header-cell>
                    <table-header-cell field="export_date_first" label="Eerste export datum"
                      >Eerste export datum</table-header-cell
                    >
                    <table-header-cell field="export_date_last" label="Laatste export datum"
                      >Laatste export datum</table-header-cell
                    >
                  </template>

                  <template #row="{ record }">
                    <table-row-cell field="auth0_org_name">{{ record.auth0_org_name }}</table-row-cell>
                    <table-row-cell field="description">{{ record.description }}</table-row-cell>
                    <table-row-cell field="name">{{ record.name }}</table-row-cell>
                    <table-row-cell field="address">{{ record.address }}</table-row-cell>
                    <table-row-cell field="zip">{{ record.zip }}</table-row-cell>
                    <table-row-cell field="place">{{ record.place }}</table-row-cell>
                    <table-row-cell field="export_count">{{ record.export_count }}</table-row-cell>
                    <table-row-cell field="edit_count">{{ record.edit_count }}</table-row-cell>
                    <table-row-cell field="date_created">
                      {{ $filter.dateFromEpochDay(record.date_created) }}</table-row-cell
                    >
                    <table-row-cell field="date_edited">{{
                      $filter.dateFromEpochDay(record.date_edited)
                    }}</table-row-cell>
                    <table-row-cell field="export_date_first">
                      {{ $filter.dateFromEpochDay(record.export_date_first) }}</table-row-cell
                    >
                    <table-row-cell field="export_date_last">{{
                      $filter.dateFromEpochDay(record.export_date_last)
                    }}</table-row-cell>
                  </template>
                </data-table>
              </template>

              <!-- organisations -->
              <template v-slot:panel-organisaties>
                <data-table
                  :data="useSurveyAdmin.organisations"
                  :loading="useSurveyAdmin.loadHandler.somethingLoading.value"
                  @row-click="organisationClick"
                >
                  <template #toolbar>
                    <table-filters-button />
                    <search-field />
                  </template>

                  <template #header>
                    <table-header-cell field="name" label="Naam">Naam</table-header-cell>
                  </template>

                  <template #row="{ record }">
                    <table-row-cell field="name">{{ record.name }}</table-row-cell>
                  </template>
                </data-table>
              </template>

              <!-- manual -->
              <template v-slot:panel-handleiding>
                <!-- algemeen -->
                <baseCard
                  class="max-h-full overflow-y-auto"
                  v-if="!useManualDetails.loadHandler.states.get_manual_details"
                >
                  <baseButton
                    @action="useManualDetails.uploadManual()"
                    :loading="useManualDetails.loadHandler.states.upload_manual"
                    :disabled="!useManualDetails.canSave.value"
                    >Opslaan</baseButton
                  >
                  <baseForm :handler="useManualDetails.manualForm" />
                </baseCard>
              </template>
              <template v-slot:panel-users>
                <usersTab />
              </template>
              <template v-slot:panel-organisations>
                <organisationsTab />
              </template>
            </baseTabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import surveyAdminHandler from '@/use/surveyAdminHandler'
import exportExcelHandler from '@/use/exportExcelHandler'
import filterHandler from '@/use/filterHandler'
import tabHandler from '@/use/tabHandler'
import manualDetailHandler from '@/use/manualDetailHandler'
import organisationsTab from '@/views/admin/viewOrganisations.vue'
import usersTab from '@/views/admin/viewUsers.vue'

// handlers
const useSurveyAdmin = surveyAdminHandler()
const router = useRouter()
const useExportExcel = exportExcelHandler()
const useManualDetails = manualDetailHandler()
const useFilter = filterHandler()

function onExport(data) {
  // Format the records to handle date fields
  const formattedRecords = data.records.map((record) => ({
    ...record,
    date_created: useFilter.filter(record.date_created, 'dateFromEpochDayExcel'),
    date_edited: useFilter.filter(record.date_edited, 'dateFromEpochDayExcel'),
    export_date_first: useFilter.filter(record.export_date_first, 'dateFromEpochDayExcel'),
    export_date_last: useFilter.filter(record.export_date_last, 'dateFromEpochDayExcel'),
  }))

  useExportExcel.exportExcel('Opnameoverzicht', 'overzicht', formattedRecords, data.fields)
}

// tabs
const tabOptions = {
  tabs: [
    { id: 'opnameoverzicht', label: 'Opnameoverzicht', active: true },
    { id: 'organisaties', label: 'Organisatie Templates', active: true },
    { id: 'handleiding', label: 'Handleiding', active: true },
    { id: 'users', label: 'Gebruikers', active: true },
    { id: 'organisations', label: 'Organisaties', active: true },
  ],
}

const tabs = tabHandler()

function organisationClick(event) {
  const organisationID = event.id

  router.push({
    name: 'organisations',
    params: { objectId: organisationID },
  })
}

onMounted(() => {
  useSurveyAdmin.getRecords()
  tabs.create(tabOptions)
  useManualDetails.getDetails()
})
</script>
