<template>
  <th class="px-3 py-3" v-if="tableState.visibleColumns.value.includes(field)">
    <div class="" v-click-outside="() => (filterIsOpen = false)">
      <div class="flex relative items-center cursor-pointer gap-2">
        <chevron-up-down
          v-if="sortable"
          @click="tableState.updateSort(field)"
          class="h-5 w-5"
          :class="{
            'text-gray-400': tableState.sortField.value !== field,
            'text-sky-700': tableState.sortField.value === field,
          }"
        />
        <slot />
        <div v-if="filterable" @click="filterIsOpen = !filterIsOpen">
          <featherIcon class="w-4 text-gray-400" icon="FilterIcon" />
        </div>
        <div
          v-if="filterIsOpen && filterable"
          class="absolute top-full left-0 mt-3 bg-gray-100 border shadow-md p-2 rounded-md max-h-[20rem] overflow-y-auto w-64 z-50 text-gray-400 thin-scrollbar"
        >
          <div v-for="(filter, name, index) in tableState.filters.value[field]" :key="index">
            <div v-if="filter.count > 0" @click="() => (filter.active.value = !filter.active.value)">
              <div class="flex items-center space-x-2 py-1 hover:bg-gray-100 cursor-pointer">
                <!-- Stop propagation on the checkbox click -->
                <baseCheckBox
                  :model-value="filter.active.value"
                  @update:modelValue="(val) => (filter.active.value = val)"
                  @click.stop
                />
                <span class="text-gray-700 text-xs truncate">
                  {{ name }}
                </span>
                <span class="text-gray-500 text-xs ml-auto">{{ filter.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </th>
</template>

<script setup>
import { ref, inject } from 'vue'
import ChevronUpDown from '@/components/extended/chevronUpDown.vue'
const filterIsOpen = ref(false)
const props = defineProps({
  field: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'string',
  },
  filterable: {
    type: Boolean,
    default: true,
  },
  sortable: {
    type: Boolean,
    default: true,
  },
})
const tableState = inject('tableState')

tableState.addColumn(props.field, props.label || props.field, props.type)
</script>
