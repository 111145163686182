<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <div class="flex justify-center">
          <logoMain class="w-16 h-16" />
        </div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Inloggen Succesvol</h2>
        <p class="mt-2 text-center text-sm text-gray-600">Echter is uw account niet gekoppeld aan een organisatie.</p>
      </div>
      <div class="rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Warning icon -->
            <svg
              class="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800">Actie Vereist</h3>
            <div class="mt-2 text-sm text-yellow-700">
              <p>Neem contact op met WoningTool om uw account te koppelen aan een organisatie.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <button
          @click="logOut"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <featherIcon class="w-4 h-4 my-auto mr-2" icon="LogOutIcon" />
          <p class="my-auto">{{ $translate('Log out') }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import logoMain from '@/components/extended/logoMain.vue'
import { useAuth0 } from '@auth0/auth0-vue'

export default {
  name: 'ViewNoOrganization',
  components: {
    logoMain,
  },
  setup() {
    const auth = useAuth0()

    function logOut() {
      auth.logout({
        logoutParams: { returnTo: window.location.origin },
      })
    }

    return {
      logOut,
    }
  },
}
</script>
