import { useAuth0 } from '@auth0/auth0-vue'
import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import router from '@/router'

let permissions = ref([])
let organisation = ref('')

export default () => {
  const auth = useAuth0()
  const useApi = apiHandler()

  async function getUserData() {
    try {
      const user = await useApi.request('get', 'v1/users/me')
      return user.data
    } catch (error) {
      if (error.response?.status === 400) {
        return { detail: 'No active organization found. Please select or set an active organization.' }
      }
      throw error
    }
  }

  function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  function refreshPermissions() {
    return new Promise((resolve, reject) => {
      auth
        .getAccessTokenSilently()
        .then((accessToken) => {
          const parsedJWT = parseJwt(accessToken)
          const tokenPermissions = parsedJWT.permissions

          getUserData().then((data) => {
            if (data.detail === 'No active organization found. Please select or set an active organization.') {
              router.push({ name: 'no-organization' })
              resolve(false)
              return
            }

            const activeOrg = data.active_organization
            organisation.value = activeOrg.name
            if (activeOrg.proces_tool === true) {
              tokenPermissions.push('organisation:proces')
            }

            permissions.value = tokenPermissions
            resolve(true)
          })
        })
        .catch((error) => {
          console.error('Error refreshing permissions:', error)
          reject(error)
        })
    })
  }

  function canSee(permission) {
    return permissions.value.includes(permission) ? true : false
  }

  return {
    canSee,
    refreshPermissions,
    permissions,
    organisation,
  }
}
