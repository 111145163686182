<template>
  <tbody>
    <tr
      @click="$emit('row-click', record)"
      @auxclick="handleMiddleClick(record, $event)"
      :class="getRowClass(record) + ' bg-white border-b'"
      v-for="(record, index) in records"
      :key="index"
    >
      <!-- Select checkbox -->
      <td v-if="selectable" class="px-6 py-4">
        <baseCheckBox
          :model-value="isSelected(record)"
          @update:model-value="$emit('toggle-select', record, $event)"
          @click.stop
        />
      </td>

      <!-- Data cells -->
      <td class="px-6 py-4" v-for="(field, index) in visibleColumns" :key="index">
        <component v-if="field.component" :is="field.component" :value="record[field.key]" />
        <span v-else-if="field.filter">
          {{ useFilter.filter(record[field.key], field.filter) }}
        </span>
        <span v-else-if="field.type === 'select'">
          {{ getSelectLabel(field, record[field.key]) }}
        </span>
        <span v-else>
          <span v-if="field.translate_value">{{ $translate(record[field.key]) }}</span>
          <span v-else>{{ record[field.key] }}</span>
        </span>
      </td>

      <!-- Action field -->
      <td v-if="actionField !== null" class="px-6 py-4">
        <baseCheckBox :model-value="record[actionValue]" @update:model-value="$emit('action', record, $event)" />
      </td>

      <!-- Action cells -->
      <td v-if="showActions" class="px-6 py-4 max-w-[60]">
        <span v-if="exportRecordButton">
          <baseButton size="small" @action="$emit('export-record', record)" class="mr-2 my-0" :disabled="loading">
            <featherIcon class="w-4 h-4" icon="DownloadIcon" />
          </baseButton>
        </span>
        <span v-if="duplicateButton">
          <baseButton size="small" @action="$emit('duplicate', record[keyField])" class="mr-2 my-0" :disabled="loading">
            <featherIcon class="w-4 h-4" icon="CopyIcon" />
          </baseButton>
        </span>
        <span v-if="editButton">
          <baseButton size="small" @action="$emit('edit', record, index)" class="mr-2 my-0" :disabled="loading">
            <featherIcon class="w-4 h-4" icon="Edit3Icon" />
          </baseButton>
        </span>
        <span v-if="deleteButton">
          <baseButton
            size="small"
            @action="$emit('delete', record[keyField], index)"
            class="my-0"
            variant="danger"
            :disabled="loading"
          >
            <featherIcon class="w-4 h-4" icon="Trash2Icon" />
          </baseButton>
        </span>
      </td>
    </tr>

    <!-- No data message -->
    <tr v-if="showNoRecords && !loading">
      <td :colspan="totalColumns" class="py-6 text-center text-xs text-gray-700">Geen gegevens gevonden</td>
    </tr>

    <!-- Loading state -->
    <tr v-if="loading">
      <td :colspan="totalColumns" class="w-full py-6 text-center text-xs text-gray-700">
        <div class="flex justify-center w-full">
          <baseLoader :loading="loading" />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script setup>
import { computed } from 'vue'
import filterHandler from '@/use/filterHandler'

const props = defineProps({
  records: Array,
  visibleColumns: Array,
  selectable: Boolean,
  actionField: String,
  actionValue: String,
  keyField: String,
  showActions: Boolean,
  exportRecordButton: Boolean,
  duplicateButton: Boolean,
  editButton: Boolean,
  deleteButton: Boolean,
  loading: Boolean,
  showNoRecords: Boolean,
  isSelected: Function,
  getRowClass: Function,
  enableMiddleClick: Boolean,
  middleClickCallback: Function,
})

defineEmits(['row-click', 'toggle-select', 'action', 'export-record', 'duplicate', 'edit', 'delete'])

const useFilter = filterHandler()

function handleMiddleClick(record, event) {
  if (event.button === 1 && props.middleClickCallback) {
    event.preventDefault()
    props.middleClickCallback(record)
  }
}

function getSelectLabel(field, value) {
  if (field.options) {
    const option = field.options.find((opt) => opt.value === value)
    return option ? option.label : value
  }
  return value
}

const totalColumns = computed(() => {
  let count = props.visibleColumns.length
  if (props.selectable) count++
  if (props.actionField !== null) count++
  if (props.showActions) count++
  return count
})
</script>
