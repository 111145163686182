<template>
  <baseButtonDropDown variant="neutral" :translate="false" icon="EyeIcon">
    <baseButtonDropDownItem v-for="(column, index) in tableState.columns.value" :key="index">
      <div
        class="flex flex-row items-center space-x-3 hover:bg-gray-50 cursor-pointer"
        @click="column.visible = !column.visible"
      >
        <!-- Stop the click so the parent handler doesn't interfere -->
        <baseCheckBox :model-value="column.visible" @update:modelValue="(val) => (column.visible = val)" @click.stop />
        <p class="whitespace-nowrap text-xs text-gray-700">
          {{ column.label || column.name }}
        </p>
      </div>
    </baseButtonDropDownItem>
  </baseButtonDropDown>
</template>

<script setup>
import { inject } from 'vue'
const tableState = inject('tableState')
</script>
