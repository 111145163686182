export default {
  install: (app) => {
    app.config.globalProperties.$filter = {
      dateFromEpochDay(value) {
        if (value) {
          // Convert seconds to milliseconds if needed (seconds epoch is 10 digits)
          const timestamp = value.toString().length === 10 ? value * 1000 : value
          const date = new Date(timestamp)
          const dateFormatted = `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
            -2
          )}-${date.getFullYear()}`
          return dateFormatted
        }
        return '-'
      },
      toCurrencyRound(value) {
        if (typeof value !== 'number') {
          return value
        }
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        return formatter.format(value)
      },
      round(value) {
        if (typeof value !== 'number') {
          return value
        }
        const formatter = new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        return formatter.format(value)
      },
      round2(value) {
        if (typeof value !== 'number') {
          return value
        }
        const formatter = new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        return formatter.format(value)
      },
    }
  },
}
