<template>
  <!-- Select All Dropdown -->
  <baseButtonDropDown
    v-if="selectedCount > 0"
    variant="neutral"
    :display-text="`${selectedCount} rijen geselecteerd`"
    class="mr-2"
  >
    <baseButtonDropDownItem @click="selectAll">
      Alle {{ tableState.filteredData.value.length }} rijen selecteren
    </baseButtonDropDownItem>
    <baseButtonDropDownItem @click="deselectAll">Alles deselecteren</baseButtonDropDownItem>
  </baseButtonDropDown>
</template>

<script setup>
import { inject, computed } from 'vue'

const tableState = inject('tableState')
const selectedCount = computed(() => {
  return tableState.selectedRecords.value.length
})

function selectAll() {
  tableState.selectedRecords.value = tableState.filteredData.value
}

function deselectAll() {
  tableState.selectedRecords.value = []
}
</script>
