import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { ref, computed } from 'vue'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const manualForm = formHandler()
  const organisation = ref({})

  const manualnDetailFields = ref([
    {
      label: 'Handleiding',
      key: 'manual',
      type: 'file',
      accept: '.pdf',
      required: true,
    },
  ])

  function getDetails() {
    loadHandler.setLoadingState('get_manual_details', true)
    useApi.request('get', 'admin/manuals/details').then((response) => {
      manualForm.create({
        type: 'edit',
        fields: manualnDetailFields.value,
        data: response.data,
      })

      loadHandler.setLoadingState('get_manual_details', false)
    })
  }

  function uploadManual() {
    loadHandler.setLoadingState('upload_manual', true)
    const formData = manualForm.data.value
    let payload = new FormData()
    payload.append('file', formData.manual)
    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    useApi.request('upload', 'admin/manuals', payload, headers).then(() => {
      loadHandler.setLoadingState('upload_manual', false)
    })
  }

  function downloadManual() {
    loadHandler.setLoadingState('download_manual', true)

    useApi.request('download', 'v1/manuals/download').then((result) => {
      const filename = result.headers['x-filename']

      if (filename) {
        const tempLink = document.createElement('a')
        tempLink.download = filename
        tempLink.href = URL.createObjectURL(result.data)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        loadHandler.setLoadingState('download_manual', false)
      } else {
        loadHandler.setLoadingState('download_manual', false)
      }
    })
  }

  const canSave = computed(() => {
    if (manualForm.errors.value.length > 0) {
      return false
    }
    return true
  })

  return {
    getDetails,
    loadHandler,
    manualForm,
    organisation,
    uploadManual,
    manualnDetailFields,
    canSave,
    downloadManual,
  }
}
