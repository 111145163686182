import { ref, computed } from 'vue'

export function useDataTable(config) {
  const columns = ref([])
  const currentPage = ref(1)
  const data = ref(config.data || [])
  const itemsPerPage = ref(15)
  const searchQuery = ref('')
  const selectedRecords = ref([])
  const sortDirection = ref('asc')
  const sortField = ref('')
  const activeFilters = ref({})

  // Initialize active filters structure
  const initializeActiveFilters = () => {
    columns.value.forEach((col) => {
      if (!activeFilters.value[col.name]) {
        activeFilters.value[col.name] = new Map()
      }
    })
  }

  // Helper to check if a record passes active filters for all columns EXCEPT the specified column
  const recordPassesOtherFilters = (record, excludeColumn) => {
    return columns.value.every((col) => {
      // Skip the excluded column
      if (col.name === excludeColumn) return true

      const columnFilters = activeFilters.value[col.name]
      // If no active filters for this column, record passes
      if (!columnFilters || columnFilters.size === 0) return true

      // Check if the record's value for this column has an active filter
      return columnFilters.get(record[col.name]) === true
    })
  }

  // Helper to check if a record passes ALL active filters
  const recordPassesAllFilters = (record) => {
    return columns.value.every((col) => {
      const columnFilters = activeFilters.value[col.name]
      if (!columnFilters || columnFilters.size === 0) return true
      return columnFilters.get(record[col.name]) === true
    })
  }

  // Computed property for search-filtered data
  const searchFilteredData = computed(() => {
    if (!searchQuery.value) return data.value

    return data.value.filter((record) =>
      Object.values(record).some((value) => String(value).toLowerCase().includes(searchQuery.value.toLowerCase()))
    )
  })

  // Main filters computed property
  const filters = computed(() => {
    initializeActiveFilters()
    const result = {}

    columns.value.forEach((col) => {
      result[col.name] = {}

      // Get records that pass all OTHER column filters
      const relevantRecords = searchFilteredData.value.filter((record) => recordPassesOtherFilters(record, col.name))

      // Count occurrences of each value
      const valueCounts = new Map()
      relevantRecords.forEach((record) => {
        const value = record[col.name]
        valueCounts.set(value, (valueCounts.get(value) || 0) + 1)
      })

      // Create filter objects with counts and active state
      searchFilteredData.value.forEach((record) => {
        const value = record[col.name]
        if (!result[col.name][value]) {
          result[col.name][value] = {
            count: valueCounts.get(value) || 0,
            active: computed({
              get: () => activeFilters.value[col.name]?.get(value) || false,
              set: (newValue) => {
                if (!activeFilters.value[col.name]) {
                  activeFilters.value[col.name] = new Map()
                }
                if (newValue) {
                  activeFilters.value[col.name].set(value, true)
                } else {
                  activeFilters.value[col.name].delete(value)
                }
                // Reset to page 1 when filters change
                currentPage.value = 1
              },
            }),
          }
        }
      })
    })
    return result
  })

  // Final filtered data
  const filteredData = computed(() => {
    return searchFilteredData.value.filter(recordPassesAllFilters)
  })

  // Rest of the sorting and pagination logic
  const sortedData = computed(() => {
    if (!sortField.value) return filteredData.value
    return [...filteredData.value].sort((a, b) => {
      const aVal = a[sortField.value]
      const bVal = b[sortField.value]
      const direction = sortDirection.value === 'asc' ? 1 : -1
      if (aVal < bVal) return -1 * direction
      if (aVal > bVal) return 1 * direction
      return 0
    })
  })

  const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * itemsPerPage.value
    const end = start + itemsPerPage.value
    return sortedData.value.slice(start, end)
  })

  const totalPages = computed(() => Math.ceil(filteredData.value.length / itemsPerPage.value))

  function nextPage() {
    if (currentPage.value < totalPages.value) {
      currentPage.value += 1
    }
  }

  function previousPage() {
    if (currentPage.value > 1) {
      currentPage.value -= 1
    }
  }

  function updateSearch(query) {
    searchQuery.value = query
    currentPage.value = 1
  }

  function updateSort(field) {
    if (sortField.value === field) {
      sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc'
    } else {
      sortField.value = field
      sortDirection.value = 'asc'
    }
  }

  const visibleColumns = computed(() => {
    return columns.value.filter((col) => col.visible === true).map((col) => col.name)
  })

  function addColumn(columnName, columnLabel, columnType = 'string') {
    const existingColumnNames = columns.value.map((col) => col.name)
    if (!existingColumnNames.includes(columnName)) {
      columns.value.push({
        name: columnName,
        label: columnLabel,
        type: columnType,
        visible: true,
      })
      initializeActiveFilters()
    }
  }

  function toggleSelectAll(select) {
    if (select) {
      const selectedSet = new Set(selectedRecords.value)
      const newRecords = paginatedData.value.filter((record) => !selectedSet.has(record))
      selectedRecords.value.push(...newRecords)
    } else {
      const paginatedSet = new Set(paginatedData.value)
      selectedRecords.value = selectedRecords.value.filter((record) => !paginatedSet.has(record))
    }
  }

  const activeFilterCount = computed(() => {
    return Object.values(activeFilters.value).reduce((total, columnFilters) => {
      return total + (columnFilters?.size || 0)
    }, 0)
  })

  function removeFilters() {
    columns.value.forEach((col) => {
      activeFilters.value[col.name] = new Map()
    })
    currentPage.value = 1
  }

  return {
    activeFilterCount,
    removeFilters,
    addColumn,
    columns,
    currentPage,
    filteredData,
    filters,
    itemsPerPage,
    nextPage,
    paginatedData,
    previousPage,
    searchQuery,
    selectedRecords,
    sortDirection,
    sortField,
    toggleSelectAll,
    totalPages,
    updateSearch,
    updateSort,
    visibleColumns,
    data,
  }
}
