/* eslint-disable no-unused-vars */
export default () => {
  function validate(value, type, rules, payload) {
    // filter type
    const typeFilter = {
      stringStrict: isStringStrict(value),
      string: isString(value),
      number: isNumber(value),
      percentage: isPercentage(value),
      select: true,
      mixed: true,
      file: true,
      calculation: isCalculation(value),
      check: true,
      textarea: isTextarea(value),
      email: isEmail(value),
      time: isTime(value),
      phone: isPhone(value),
      password: true,
    }

    if (!typeFilter[type]) {
      return false
    }

    return true
    // const ruleFilter = {
    //   min: isMin(value),
    //   max: isMax(value),
    // }
  }

  function isStringStrict(value) {
    const pattern = /[^a-zA-Z ]/
    return !pattern.test(value)
  }

  function isString(value) {
    const pattern = /[^a-zA-Z0-9\- .(),+]/
    return !pattern.test(value)
  }

  function isTextarea(value) {
    const pattern = /[^a-zA-Z0-9\- .(),+\n]/
    return !pattern.test(value)
  }

  function isNumber(value) {
    const pattern = /^(?:(?:0|[1-9][0-9]*)(?:\.[0-9]*)?|\.[0-9]*)$|^$/
    return pattern.test(value)
  }

  function isCalculation(value) {
    const pattern = /^[1234567890./*\-+()]*$/
    return pattern.test(value)
  }

  // limited percentage
  function isPercentage(value) {
    const pattern = /^(?:(?:0|[1-9][0-9]*)(?:\.[0-9]*)?|\.[0-9]*)$|^$/
    return pattern.test(value)
  }

  function isEmail(value) {
    const pattern = /^([a-zA-Z0-9._-]*@?[a-zA-Z0-9.-]*\.?[a-zA-Z]{0,})$/
    return pattern.test(value)
  }

  function isTime(value) {
    const pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
    return pattern.test(value)
  }

  function isPhone(value) {
    const pattern = /^\d{10}$/
    return pattern.test(value)
  }

  return {
    validate,
  }
}
