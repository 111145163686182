<template>
  <div v-if="!initialized" class="flex items-center justify-center min-h-screen">
    <!-- Loading state -->
    <div class="text-center">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
      <p class="mt-4 text-gray-600">Laden...</p>
    </div>
  </div>
  <div v-else>
    <template v-if="showApp && hasOrganization">
      <component :is="layout">
        <router-view />
      </component>

      <base-calculator />

      <!-- toasts -->
      <base-toast></base-toast>

      <!-- modals -->
      <baseModal
        v-if="tooltipHandler.tooltipVisible.value"
        @close="tooltipHandler.hideTooltip()"
        :title="tooltipHandler.getTitle()"
        size="l"
      >
        <p class="text-slate-500">{{ tooltipHandler.getContent() }}</p>
      </baseModal>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar.vue'
import LayoutFull from '@/layouts/LayoutFull.vue'
import LayoutHorizontal from '@/layouts/LayoutHorizontal.vue'
import LayoutHorizontalSideBar from '@/layouts/LayoutHorizontalSideBar.vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import useTooltipHandler from '@/use/tooltipHandler'
import router from '@/router'

export default {
  setup() {
    const route = useRoute()
    const useContentGuard = contentGuardHandler()
    const tooltipHandler = useTooltipHandler()
    const showApp = ref(false)
    const initialized = ref(false)
    const hasOrganization = ref(false)

    const layoutSelector = {
      full: 'layout-full',
      horizontal: 'layout-horizontal',
      horizontal_sidebar: 'layout-horizontal-side-bar',
    }

    const layout = computed(() => {
      return layoutSelector[route.meta.layout]
    })

    onMounted(async () => {
      try {
        const result = await useContentGuard.refreshPermissions()
        hasOrganization.value = result
        showApp.value = true

        if (result) {
          if (useContentGuard.permissions.value.includes('organisation:proces')) {
            await router.push({ name: 'addresses' })
          } else {
            await router.push({ name: 'surveys' })
          }
        } else {
          await router.push({ name: 'no-organization' })
        }
      } catch (error) {
        console.error('Error refreshing permissions:', error)
        showApp.value = true
        hasOrganization.value = false
        await router.push({ name: 'no-organization' })
      } finally {
        initialized.value = true
      }
    })

    return {
      layout,
      tooltipHandler,
      showApp,
      initialized,
      hasOrganization,
    }
  },
  components: {
    LayoutHorizontal,
    LayoutHorizontalSideBar,
    LayoutFull,
    navigationBar,
  },
}
</script>
