<template>
  <td class="px-6 py-4">
    <baseCheckBox :model-value="checked" @update:model-value="toggleSelect" />
  </td>
</template>

<script setup>
import { computed, inject } from 'vue'

const checked = computed(() => {
  return tableState.paginatedData.value.every((record) => tableState.selectedRecords.value.includes(record))
})
const tableState = inject('tableState')

function toggleSelect() {
  if (checked.value) {
    tableState.toggleSelectAll(false)
  } else {
    tableState.toggleSelectAll(true)
  }
}
</script>
