<template>
  <baseButtonDropDown
    v-if="tableState.activeFilterCount.value > 0"
    variant="neutral"
    :display-text="`${tableState.activeFilterCount.value} filters actief`"
    :translate="false"
  >
    <baseButtonDropDownItem @click="tableState.removeFilters">Alle filters wissen</baseButtonDropDownItem>
  </baseButtonDropDown>
</template>

<script setup>
import { inject } from 'vue'

const tableState = inject('tableState')
</script>
