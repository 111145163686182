<template>
  <div>
    <div class="relative" ref="containerRef">
      <base-button :variant="variant" :size="size" :loading="loading" :disabled="disabled" @click="toggle">
        <div class="flex flex-row items-center">
          <span v-if="!icon" class="text-xs">
            <span v-if="translate">{{ $translate(displayText) }}</span>
            <span v-else>{{ displayText }}</span>
          </span>
          <span v-else>
            <featherIcon class="w-4" :icon="icon" />
          </span>
          <span v-if="!icon" class="ml-2">
            <featherIcon class="w-4" icon="ChevronDownIcon" />
          </span>
        </div>
      </base-button>

      <div
        v-if="isOpen"
        v-click-outside="close"
        class="rounded shadow-md bg-white absolute mt-2 z-20 py-1"
        :class="{ 'right-aligned': alignRight, 'left-aligned': !alignRight }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  variant: { type: String, default: 'primary' },
  size: { type: String, default: 'normal' },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  translate: { type: Boolean, default: true },
  displayText: { type: String, default: 'normal' },
  icon: { type: String, default: '' },
  alignRight: { type: Boolean, default: false },
})

const emit = defineEmits(['open', 'close'])

const containerRef = ref(null)
const isOpen = ref(false)

const toggle = () => {
  isOpen.value = !isOpen.value
  emit(isOpen.value ? 'open' : 'close')
}

const close = (event) => {
  // Check if click was on our button/container
  if (containerRef.value && containerRef.value.contains(event.target)) {
    return
  }
  isOpen.value = false
  emit('close')
}

defineExpose({
  isOpen,
  toggle,
  close,
})
</script>

<style scoped>
.right-aligned {
  right: 0;
}
.left-aligned {
  left: 0;
}
</style>
