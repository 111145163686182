<template>
  <td class="px-6 py-4">
    <baseCheckBox :model-value="checked" @update:model-value="toggleSelect" />
  </td>
</template>

<script setup>
import { computed, inject } from 'vue'

const props = defineProps({
  record: Object,
})

const tableState = inject('tableState')
const checked = computed(() => {
  return tableState.selectedRecords.value.includes(props.record)
})

function toggleSelect() {
  if (checked.value) {
    tableState.selectedRecords.value = tableState.selectedRecords.value.filter((record) => record != props.record)
  } else {
    tableState.selectedRecords.value.push(props.record)
  }
}
</script>
