<template>
  <baseButtonDropDown variant="neutral" :translate="false" icon="SettingsIcon">
    <baseButtonDropDownItem
      @click="
        $emit('export', {
          records: tableState.filteredData.value,
          fields: tableState.columns.value
            .filter((col) => col.visible)
            .map((col) => ({
              key: col.name,
              label: col.label,
            })),
        })
      "
    >
      <div class="flex flex-row">
        <featherIcon class="mr-2 w-4 h-4 text-gray-500" icon="DownloadIcon" />
        <p class="whitespace-nowrap">Download xlsx</p>
      </div>
    </baseButtonDropDownItem>
  </baseButtonDropDown>
</template>

<script setup>
import { inject } from 'vue'
defineEmits(['export'])

const tableState = inject('tableState')
</script>
