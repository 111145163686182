<template>
  <td v-if="tableState.visibleColumns.value.includes(field)" class="px-6 py-4"><slot></slot></td>
</template>

<script setup>
import { inject } from 'vue'
const tableState = inject('tableState')

defineProps({
  field: {
    type: String,
    required: true,
  },
})
</script>
