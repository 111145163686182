<template>
  <thead class="text-xs text-gray-700 bg-gray-100">
    <tr>
      <!-- Selection Column -->
      <th v-if="selectable" scope="col" class="px-6 py-3">
        <baseCheckBox
          class="text-gray-500"
          :model-value="allSelectedOnPage"
          @update:model-value="$emit('toggle-select-all-page', $event)"
        />
      </th>

      <!-- Data Columns -->
      <th v-for="(field, index) in visibleColumns" :key="index" scope="col" class="px-3 py-3">
        <div v-if="sortable" class="flex items-center relative">
          <!-- Sort Controls -->
          <chevronupdown
            :class="['h-5 w-5 min-w-5 min-h-5 my-auto cursor-pointer', { 'text-gray-400': index !== sortFieldIndex }]"
            @click="$emit('sort', field, index)"
          />

          <span class="my-auto mx-1">{{ $translate(field.label) }}</span>

          <!-- Filter Controls -->
          <div v-if="showFilters" v-click-outside="() => closeFilter(field)">
            <featherIcon
              class="w-4 h-4 ml-1 my-auto cursor-pointer"
              :class="getFilterIconClass(field.key)"
              icon="FilterIcon"
              @click="toggleFilter(field)"
            />

            <!-- Filter Dropdown -->
            <div
              v-if="isFilterOpen(field)"
              class="absolute bg-white shadow-md p-2 rounded-md max-h-[20rem] overflow-y-auto w-64 z-50 text-gray-400"
              style="top: 100%; left: 0"
            >
              <div
                v-for="[value, data] in getFilterOptions(field)"
                :key="value"
                class="flex items-center space-x-2 py-1 hover:bg-gray-100 cursor-pointer"
                @click="updateFilter(field, value)"
              >
                <baseCheckBox
                  class="flex-shrink-0 text-gray-500"
                  :model-value="isFilterSelected(field.key, value)"
                  :disabled="isFilterOptionDisabled(field.key, value)"
                  @update:model-value="$emit('update-filter', field, value, $event)"
                  @click.stop
                />
                <span class="text-gray-700 text-xs truncate">
                  {{ formatFilterValue(field, value) }}
                </span>
                <span class="text-gray-500 text-xs ml-auto">{{ data.count }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="px-3">{{ $translate(field.label) }}</div>
      </th>

      <!-- Action Columns -->
      <th v-if="actionField !== null" scope="col" class="px-6 py-3">{{ actionField }}</th>
      <th v-if="showActions" scope="col" class="px-6 py-3 max-w-[60]">Acties</th>
    </tr>
  </thead>
</template>

<script setup>
import { ref } from 'vue'
import chevronupdown from '@/components/extended/chevronUpDown.vue'

const props = defineProps({
  selectable: Boolean,
  sortable: Boolean,
  showFilters: Boolean,
  visibleColumns: Array,
  sortFieldIndex: Number,
  actionField: String,
  showActions: Boolean,
  allSelectedOnPage: Boolean,
  isColumnFiltered: Function,
  filterRecords: Object,
  availableFilterOptions: Object,
  columnFilters: Object,
})

const emit = defineEmits(['toggle-select-all-page', 'sort', 'update-filter'])

const filterToShow = ref('')

const isFilterOpen = (field) => filterToShow.value === field.key
const toggleFilter = (field) => {
  filterToShow.value = filterToShow.value === field.key ? '' : field.key
}
const closeFilter = (field) => {
  if (filterToShow.value === field.key) {
    filterToShow.value = ''
  }
}

const getFilterIconClass = (columnKey) => ({
  'text-sky-700': props.isColumnFiltered(columnKey),
  'text-gray-400': !props.isColumnFiltered(columnKey),
})

const isFilterSelected = (columnKey, value) => props.columnFilters?.[columnKey]?.[value] !== undefined

const isFilterOptionDisabled = (columnKey, value) =>
  !props.availableFilterOptions[columnKey]?.has(value) && !isFilterSelected(columnKey, value)

const getFilterOptions = (field) => Object.entries(props.filterRecords[field.key] || {})

const formatFilterValue = (field, value) =>
  field.type === 'date' ? props.$filter.dateFromEpochDay(parseInt(value)) : value

const updateFilter = (field, value) => emit('update-filter', field, value, !isFilterSelected(field.key, value))
</script>
