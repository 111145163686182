<!-- SearchField.vue -->
<template>
  <div class="flex flex-row items-center gap-2 bg-white shadow-md rounded text-sm text-gray-500">
    <div class="pl-3 pointer-events-none">
      <featherIcon class="w-5 h-5" icon="SearchIcon" />
    </div>
    <div>
      <input
        :value="tableState.searchQuery.value"
        class="focus:outline-none w-40 h-12"
        placeholder="zoeken"
        @input="tableState.updateSearch($event.target.value)"
      />
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
const tableState = inject('tableState')
</script>
