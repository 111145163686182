<template>
  <div>
    <baseTable
      :fields="taskFields"
      :records="tasks"
      :records-per-page="10"
      actionField="Afgerond"
      @action="completeTask"
      actionValue="completed"
      :sortable="true"
      :add-button="true"
      :edit-button="true"
      @edit="onOpenEditTask"
      @add="onOpenAddTask"
    />
  </div>

  <baseModal v-if="showAddModal || showEditModal" @close="closeModal" title="Add task">
    <form class="w-full flex flex-col">
      <baseInput v-model="name" label="Titel" type="string" id="title" />
      <baseTextBox v-model="description" label="Omschrijving" id="description" />
      <baseDatePicker v-model="deadline" label="Deadline" />
      <baseSelectField v-model="user_id" label="Toegewezen aan" :options="users" zIndex="z-[2]" />
      <baseSelectField v-if="showAddModal" v-model="step_id" label="Stap" :options="steps" zIndex="z-[0]" />
    </form>
    <template v-slot:buttons>
      <baseButton v-if="showAddModal" @action="addTask" class="mr-2">Add</baseButton>
      <baseButton v-if="showEditModal" @action="editTask" class="mr-2">Edit</baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import apiHandler from '@/use/apiHandler'

const route = useRoute()
const useApi = apiHandler()

const tasks = ref([])

const task_id = ref('')
const name = ref('Taak')
const description = ref('Omschrijving')
const deadline = ref(null)
const user_id = ref('')
const step_id = ref('')

const users = ref([])
const steps = ref([])

const showAddModal = ref(false)
const showEditModal = ref(false)

const taskFields = [
  { key: 'name', label: 'Titel' },
  { key: 'description', label: 'Omschrijving' },
  { key: 'deadline', label: 'Deadline', type: 'date' },
  { key: 'user_id', label: 'Toegewezen aan' },
]

onMounted(() => {
  loadTasks()
  loadUsers()
  loadSteps()
})

const onOpenAddTask = () => {
  showAddModal.value = true
}

const onOpenEditTask = (task) => {
  task_id.value = task.id
  name.value = task.name
  description.value = task.description
  deadline.value = task.deadline
  user_id.value = task.users[0]?.id
  step_id.value = task.step_id
  showEditModal.value = true
}

const closeModal = () => {
  task_id.value = ''
  name.value = 'Taak'
  description.value = 'Omschrijving'
  deadline.value = null
  user_id.value = ''

  showAddModal.value = false
  showEditModal.value = false
}

async function addTask() {
  const payload = {
    name: name.value,
    description: description.value,
    users: [user_id.value],
    address_id: route.params.objectId,
    step_id: step_id.value,
  }
  if (deadline.value) {
    payload.deadline = deadline.value
  }
  await useApi.request('post', 'v1/tasks', payload)
  closeModal()
  loadTasks()
}

async function editTask() {
  const payload = {
    name: name.value,
    description: description.value,
    users: [user_id.value],
  }
  if (deadline.value) {
    payload.deadline = deadline.value
  }
  await useApi.request('patch', 'v1/tasks/' + task_id.value, payload)
  closeModal()
  loadTasks()
}

async function loadTasks() {
  const project_id = route.params.projectId
  if (!project_id) return

  const response = await useApi.request('get', `v1/projects/${project_id}/addresses/${route.params.objectId}/tasks`)
  tasks.value = response.data
  tasks.value.forEach((task) => {
    task.user_id = task.users.map((user) => user.name).join(', ')
  })
}

async function loadUsers() {
  const response = await useApi.request('get', 'v1/organizations/users')
  users.value = response.data.map((user) => ({ label: user.name, value: user.id }))
}

async function loadSteps() {
  const response = await useApi.request('get', `v1/projects/${route.params.projectId}/steps`)
  steps.value = response.data.map((step) => ({ label: step.name, value: step.id }))
}

async function completeTask(record, completed) {
  tasks.value = tasks.value.map((task) => (task.id === record.id ? { ...task, completed: completed } : task))
  await useApi.request('patch', 'v1/tasks/' + record.id, { completed: completed })
}
</script>
