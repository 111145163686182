<template>
  <div class="flex flex-row justify-between mb-4">
    <!-- Add Button -->
    <div class="flex items-center">
      <baseButton
        v-if="addButton"
        class="px-4 text-2xl py-1 my-2"
        size="large"
        @action="$emit('add')"
        :disabled="loading"
      >
        +
      </baseButton>
    </div>

    <!-- Toolbar Right Section -->
    <div class="flex flex-row items-center">
      <!-- Multi Edit Button -->
      <div v-if="selectable && multiEdit && totalSelected > 0" class="flex items-center">
        <baseButton variant="neutral" class="mx-2" @action="$emit('multi-edit')">
          <div class="flex flex-row">
            <span class="text-xs">Bewerk {{ totalSelected }} items</span>
            <span class="ml-2">
              <featherIcon class="w-4" icon="Edit3Icon" />
            </span>
          </div>
        </baseButton>
      </div>

      <!-- Select All Dropdown -->
      <baseButtonDropDown
        v-if="selectable && selectAllOption && totalSelected > 0"
        variant="neutral"
        :display-text="`${totalSelected} rijen geselecteerd`"
      >
        <baseButtonDropDownItem @click="$emit('select-all-records')">
          Alle {{ recordsLength }} rijen selecteren
        </baseButtonDropDownItem>
        <baseButtonDropDownItem @click="$emit('deselect-all')">Alles deselecteren</baseButtonDropDownItem>
      </baseButtonDropDown>

      <!-- Filter Status Dropdown -->
      <baseButtonDropDown
        v-if="hasActiveFilters"
        variant="neutral"
        :display-text="`${activeFilterCount} filters actief`"
        :translate="false"
      >
        <baseButtonDropDownItem @click="$emit('clear-filters')">Alle filters wissen</baseButtonDropDownItem>
      </baseButtonDropDown>

      <!-- Export Button Dropdown -->
      <baseButtonDropDown v-if="exportButton" variant="neutral" :translate="false" icon="SettingsIcon" class="mr-2">
        <baseButtonDropDownItem @click="$emit('export')">
          <div class="flex flex-row">
            <featherIcon class="mr-2 w-4 h-4 text-gray-500" icon="DownloadIcon" />
            <p class="whitespace-nowrap">Download xlsx</p>
          </div>
        </baseButtonDropDownItem>
      </baseButtonDropDown>

      <!-- Visible Columns Dropdown -->
      <baseButtonDropDown variant="neutral" :translate="false" icon="EyeIcon">
        <baseButtonDropDownItem v-for="(column, index) in columnStates" :key="index">
          <div class="flex flex-row">
            <baseCheckBox
              :model-value="column.visible"
              @update:model-value="(value) => $emit('toggle-column', column, value)"
            />
            <p class="whitespace-nowrap">{{ column.label }}</p>
          </div>
        </baseButtonDropDownItem>
      </baseButtonDropDown>

      <!-- Search Input -->
      <div v-if="search" class="relative px-4 py-3 bg-white shadow-md rounded text-sm text-gray-500">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
        <input
          v-model="searchQuery"
          class="pl-8 focus:outline-none w-40"
          placeholder="zoeken"
          @input="$emit('search', searchQuery)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  addButton: Boolean,
  exportButton: Boolean,
  search: Boolean,
  selectable: Boolean,
  selectAllOption: Boolean,
  multiEdit: Boolean,
  totalSelected: Number,
  recordsLength: Number,
  hasActiveFilters: Boolean,
  activeFilterCount: Number,
  loading: Boolean,
  columnStates: Array,
})

defineEmits([
  'add',
  'export',
  'search',
  'multi-edit',
  'select-all-records',
  'deselect-all',
  'clear-filters',
  'toggle-column',
])

const searchQuery = ref('')
</script>
