<!-- DataTable.vue -->
<template>
  <div>
    <div class="flex justify-end items-end pb-2 gap-2">
      <slot name="toolbar" />
    </div>
    <div class="relative shadow-md sm:rounded-lg border">
      <table class="w-full text-sm text-left text-gray-500 table-auto">
        <thead class="text-xs text-gray-700 bg-gray-100">
          <tr>
            <slot name="header" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in tableState.paginatedData.value"
            :key="record[keyField]"
            :class="[
              'bg-white border-b hover:bg-slate-100 p-2 m-2 cursor-pointer',
              tableState.selectedRecords.value.includes(record) ? 'bg-blue-100' : '',
            ]"
            @click="onRowClick(record)"
            @click.middle="$emit('row-middle-click', record)"
          >
            <slot name="row" :record="record" />
          </tr>
        </tbody>
      </table>
    </div>
    <table-pagination
      :table-state="tableState"
      :start-record="(tableState.currentPage.value - 1) * tableState.itemsPerPage.value + 1"
      :end-record="
        Math.min(tableState.currentPage.value * tableState.itemsPerPage.value, tableState.filteredData.value.length)
      "
      :total-records="tableState.filteredData.value.length"
    />
  </div>
</template>

<script setup>
import { useDataTable } from '@/use/dataTableHandler'
import tablePagination from './table/tablePagination2'
import { provide, watch } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  keyField: {
    type: String,
    default: 'object_id',
  },
  columns: {
    type: Array,
    default: () => [],
    // Each column object can have: { field: string, label: string, filterable: boolean }
  },
  selectable: {
    type: Boolean,
    default: false,
  },
})

// Define the emits
const emit = defineEmits(['row-click', 'row-middle-click'])

const tableState = useDataTable({
  data: props.data,
})

watch(
  () => props.data,
  (newData) => {
    tableState.data.value = newData
  },
  { deep: true }
)

provide('tableState', tableState)

function onRowClick(record) {
  if (props.selectable) {
    // Toggle selection using the same mechanism as tableSelectCheckbox
    if (tableState.selectedRecords.value.includes(record)) {
      tableState.selectedRecords.value = tableState.selectedRecords.value.filter((r) => r !== record)
    } else {
      tableState.selectedRecords.value.push(record)
    }
  } else {
    emit('row-click', record)
  }
}

defineExpose({
  tableState,
})
</script>
