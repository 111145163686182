// Alles in de components folder wordt geladen
const requireComponent = require.context(
  // Look for files in the components directory and its subdirectories
  './',
  // Look in subdirectories
  true,
  // Only include .vue files
  /\.vue$/
)

const components = {}

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName)
  // Get the PascalCase name of the component
  // Remove the "./" from the beginning and ".vue" from the end
  const componentName = fileName
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
    // Remove path and just keep filename
    .split('/')
    .pop()

  // Register component globally
  components[componentName] = componentConfig.default || componentConfig
})

export default components
