<template>
  <div class="h-full min-h-0 overflow-y-auto">
    <div class="flex flex-col h-full">
      <div class="flex justify-between items-center">
        <basePageTitle :title="useContentGuard.organisation.value" subtitle="Organisatie" />
      </div>
      <div class="flex-grow pr-1 pb-2 mt-4">
        <baseTable
          :fields="fields"
          :records="users"
          :records-per-page="10"
          :sortable="true"
          :addButton="false"
          :deleteButton="false"
        />
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-2 p-2 w-64">
    <baseSelect v-model:selected="selectedOrganisation" :options="organisations" />
    <baseButton class="mr-auto select-none" @click="changeOrganisation" :disabled="!selectedOrganisation">
      Wijzig
    </baseButton>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import apiHandler from '@/use/apiHandler'

const useContentGuard = contentGuardHandler()
const useApi = apiHandler()

const fields = [
  { key: 'name', label: 'Naam', sortable: true },
  { key: 'email', label: 'E-mail', sortable: true },
]

const users = ref([])
const organisations = ref([])
const selectedOrganisation = ref(null)

onMounted(async () => {
  try {
    const { data } = await useApi.request('get', 'v1/organizations/users')
    users.value = data
    const { data: orgData } = await useApi.request('get', 'v1/users/me/organizations')
    organisations.value = orgData.map((org) => ({ label: org.name, value: org.id }))

    if (organisations.value.length > 0) {
      selectedOrganisation.value = organisations.value[0]
    }
  } catch (error) {
    console.error('Error fetching users:', error)
  }
})

async function changeOrganisation() {
  if (selectedOrganisation.value) {
    try {
      await useApi.request('patch', 'v1/users/me', { active_org_id: selectedOrganisation.value.value })
      window.location.reload()
    } catch (error) {
      console.error('Error changing organisation:', error)
    }
  }
}
</script>
