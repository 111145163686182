import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import { ref } from 'vue'

let project_name = ref('')

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const project = ref({})
  const maatregelenCategorieen = ref([])

  async function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    try {
      const id = payload['id']

      // get project and addresses
      const response = await useApi.request('get', `v1/projects/${id}`)
      const responseAddresses = await useApi.request('get', `v1/projects/${id}/addresses`)

      // merge
      const response_data = response.data
      response_data['addresses'] = responseAddresses.data

      project.value = response_data
      const config = response_data.config

      project_name.value = project.value.name

      if ('maatregelen_categorieen' in config) {
        maatregelenCategorieen.value = config['maatregelen_categorieen']
      }

      return project.value
    } finally {
      loadHandler.setLoadingState('get_details', false)
    }
  }

  return {
    loadHandler,
    getDetails,
    project,
    project_name,
  }
}
