<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col">
        <!-- title -->
        <div>
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <baseBackButton />
              </span>
              <!-- pagetitle when found -->
              <basePageTitle
                v-if="!surveyDetails.notFound.value && !surveyDetails.loadHandler.states.get_records"
                :title="`${surveyDetails.surveyData.value.description}`"
              >
                <p class="text-slate-500 text-xs">
                  {{ surveyDetails.surveyData.value.address }}
                </p>
                <p class="text-slate-500 text-xs">
                  {{ surveyDetails.surveyData.value.zip }}
                </p>
                <p class="text-slate-500 text-xs">
                  {{ surveyDetails.surveyData.value.place }}
                </p>
              </basePageTitle>
              <baseLoader
                v-else
                :loading="surveyDetails.loadHandler.states.get_records && !surveyDetails.notFound.value"
                class="text-xl text-slate-500 my-6"
              />
              <!-- page title when not found -->
              <basePageTitle
                v-if="surveyDetails.notFound.value"
                :title="`Opname: niet gevonden`"
                :subtitle="'Dit kan komen doordat de opname niet meer bestaat of omdat het verkeerde opname-id is gebruikt in de adresbalk.'"
              />
            </div>

            <!-- metrics -->
            <div class="flex flex-row items-center">
              <metricsBox
                v-if="!surveyDetails.loadHandler.states.get_records"
                :loading="surveyDetails.loadHandler.states.get_records"
                :show-values="surveyDetails.showBerekening.value"
                :huidig="useFormulaHuidig"
                :nieuw="useFormulaNieuw"
                :basic="surveyDetails.surveyData.value.basic"
              />
            </div>
          </div>
        </div>
        <!-- content columns -->
        <div
          class="flex flex-row min-h-0"
          v-if="!surveyDetails.notFound.value && !surveyDetails.loadHandler.states.get_records"
        >
          <div class="basis-full">
            <baseTabs :handler="tabs" :loading="surveyDetails.loadHandler.states.get_records" @changed="onTabChange">
              <template v-slot:button>
                <div class="flex">
                  <!-- export button -->
                  <baseButton
                    @action="onExport()"
                    :loading="surveyDetails.loadHandler.states.generate_report"
                    class="my-2 shadow-md text-sm mr-1"
                    :disabled="useHypotheek.errors.value.length > 0 || !surveyDetails.showBerekening.value"
                  >
                    <!-- surveyDetails.showBerekening.value -->
                    <featherIcon class="w-5 h-5" icon="DownloadIcon" />
                  </baseButton>

                  <!-- calculator button -->
                  <baseButton
                    @action="clickCalculator()"
                    :disabled="surveyDetails.loadHandler.states.get_records"
                    class="my-2 shadow-md text-sm mr-1"
                  >
                    <CalculatorIcon class="h-5 w-5" />
                  </baseButton>

                  <!-- save button -->
                  <baseButton
                    @action="surveyDetails.editRecord()"
                    :loading="surveyDetails.loadHandler.states.edit_records"
                    :disabled="surveyDetails.loadHandler.states.get_records"
                    class="my-2 shadow-md text-sm mr-1"
                  >
                    <featherIcon class="w-5 h-5" icon="SaveIcon" />
                  </baseButton>

                  <!-- export year -->
                  <baseButton
                    v-if="useContentGuard.canSee('admin:read')"
                    @action="() => (exportModal = true)"
                    class="my-2 shadow-md text-sm hover:bg-sky-800 transition-colors"
                  >
                    <featherIcon class="w-5 h-5" icon="TableIcon" />
                  </baseButton>
                </div>
              </template>

              <!-- algemeen -->
              <template v-slot:panel-algemeen>
                <baseCard class="max-h-full overflow-y-auto">
                  <baseForm :handler="surveyDetails.soortForm" :tooltipHandler="tooltipHandler" />
                </baseCard>
              </template>

              <!-- installaties huidig -->
              <template v-slot:panel-installaties_huidig>
                <baseCard class="max-h-full overflow-y-auto">
                  <installatiesForm :handler="surveyDetails.installatieForm" />
                </baseCard>
              </template>

              <!-- Klimaatzones huidig -->
              <template v-slot:panel-klimaatzones_huidig>
                <baseCard class="max-h-full overflow-y-auto">
                  <klimaatZone
                    :handler="surveyDetails.klimaatZonesForm"
                    :basic="surveyDetails.surveyData.value.basic"
                    title="Klimaatzones huidig"
                  />
                </baseCard>
              </template>

              <!-- installaties nieuw -->
              <template v-slot:panel-installaties_nieuw>
                <div class="flex flex-row h-full">
                  <baseCard class="max-h-full overflow-y-auto basis-1/2 mr-4">
                    <installatiesForm :handler="surveyDetails.installatieForm" :disabled="true" title="Huidig" />
                  </baseCard>
                  <baseCard class="max-h-full overflow-y-auto basis-1/2">
                    <installatiesForm
                      :handler="surveyDetails.installatieFormNieuw"
                      :syncHandler="surveyDetails.installatieForm"
                      title="Nieuw"
                    />
                  </baseCard>
                </div>
              </template>

              <!-- Klimaatzones nieuw -->
              <template v-slot:panel-klimaatzones_nieuw>
                <div class="flex flex-row h-full">
                  <baseCard class="max-h-full overflow-y-auto basis-1/2 mr-4">
                    <p class="text-lg text-slate-500">Huidig</p>
                    <klimaatZone
                      :handler="surveyDetails.klimaatZonesForm"
                      :basic="surveyDetails.surveyData.value.basic"
                      title=""
                      :disabled="true"
                    />
                  </baseCard>

                  <baseCard class="max-h-full overflow-y-auto basis-1/2">
                    <p class="text-lg text-slate-500">Nieuw</p>
                    <klimaatZone
                      :handler="surveyDetails.klimaatZonesFormNieuw"
                      :basic="surveyDetails.surveyData.value.basic"
                      title="Begane grond"
                      :syncHandler="surveyDetails.klimaatZonesForm"
                    />
                  </baseCard>
                </div>
              </template>

              <!-- Maatregelen -->
              <template v-slot:panel-maatregelen>
                <baseCard class="max-h-full">
                  <p class="text-l text-slate-500 my-2 font-bold">Maatregelen</p>
                  <p class="text-sm text-slate-500 mb-2">
                    <maatregelenBox
                      :handler="useMaatregelen"
                      :categorieen="surveyDetails.surveyData.value.measure_categories"
                    />
                  </p>
                </baseCard>
              </template>

              <!-- berekening -->
              <template v-slot:panel-berekening>
                <baseCard v-if="surveyDetails.showBerekening.value" class="max-h-full overflow-y-auto">
                  <resultsBox :huidig="useFormulaHuidig" :nieuw="useFormulaNieuw" :surveyDetails="surveyDetails" />
                </baseCard>
                <baseCard v-else class="max-h-full overflow-y-auto">
                  <errorBox :handler="surveyDetails" />
                </baseCard>
              </template>

              <!-- Resultaat -->
              <template v-slot:panel-resultaat>
                <baseCard v-if="surveyDetails.showBerekening.value" class="max-h-full overflow-y-auto">
                  <!-- verbruik -->
                  <p class="text-l text-slate-500 my-2 font-bold">Verbruik</p>
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3"></p>
                    <p class="basis-1/3 font-bold">Huidig</p>
                    <p class="basis-1/3 font-bold">Nieuw</p>
                  </div>

                  <!-- totale behoefte gas -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Gas</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaHuidig.result.data_total.verbruik_totaal_m3, 'round') }} m3
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaNieuw.result.data_total.verbruik_totaal_m3, 'round') }} m3
                    </p>
                  </div>

                  <!-- totale behoefte warmte -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Warmte</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaHuidig.result.data_total.verbruik_totaal_gj, 'round') }} GJ
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaNieuw.result.data_total.verbruik_totaal_gj, 'round') }} GJ
                    </p>
                  </div>

                  <!-- totale behoefte elektriciteit -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Elektriciteit: totale behoefte</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaHuidig.result.data_total.eigen_behoefte_totaal, 'round') }}
                      kWh
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaNieuw.result.data_total.eigen_behoefte_totaal, 'round') }} kWh
                    </p>
                  </div>

                  <!-- Ontrekking van het net -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Elektriciteit: ontrekking van het net</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaHuidig.result.data_total.van_het_net_kwh, 'round') }} kWh
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaNieuw.result.data_total.van_het_net_kwh, 'round') }} kWh
                    </p>
                  </div>

                  <!-- Productie -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Elektriciteit: productie</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaHuidig.result.data_total.opwekking_totaal_kwh, 'round') }} kWh
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaNieuw.result.data_total.opwekking_totaal_kwh, 'round') }} kWh
                    </p>
                  </div>

                  <!-- Teruglevering -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Elektriciteit: teruglevering aan het net</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaHuidig.result.data_total.teruglevering_totaal_kwh, 'round') }}
                      kWh
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaNieuw.result.data_total.teruglevering_totaal_kwh, 'round') }}
                      kWh
                    </p>
                  </div>

                  <!-- Vermogens per systeem -->
                  <p class="text-l text-slate-500 my-2 font-bold">Vermogens per systeem</p>
                  <div class="flex flex-row text-sm text-slate-500 mb-4">
                    <p class="basis-1/3"></p>
                    <p class="basis-1/3 font-bold">Huidig</p>
                    <p class="basis-1/3 font-bold">Nieuw</p>
                  </div>

                  <!-- systemen -->
                  <div
                    class="flex flex-row text-sm text-slate-500 mb-4"
                    v-for="(klimaatzoneNaam, klimaatzoneIndex) in surveyDetails.uniekeKlimaatzones.value"
                    :key="klimaatzoneIndex"
                  >
                    <div class="basis-1/3">{{ klimaatzoneNaam }}</div>
                    <div class="basis-1/3" v-if="klimaatzoneNaam in useFormulaHuidig.result.data_total.klimaatzones">
                      <div
                        class="flex justify-between pr-8"
                        v-for="(systeem, index) in useFormulaHuidig.result.data_total.klimaatzones[klimaatzoneNaam]
                          .afgiftevermogen_summary.systemen"
                        :key="index"
                      >
                        <p>{{ systeem.type }}</p>
                        <p>{{ useFilter.filter(systeem.vermogen, 'round2') }} kW</p>
                      </div>
                    </div>
                    <div class="basis-1/3" v-else>
                      <p>-</p>
                      <p></p>
                    </div>
                    <div class="basis-1/3" v-if="klimaatzoneNaam in useFormulaNieuw.result.data_total.klimaatzones">
                      <div
                        class="flex justify-between pr-8"
                        v-for="(systeem, index) in useFormulaNieuw.result.data_total.klimaatzones[klimaatzoneNaam]
                          .afgiftevermogen_summary.systemen"
                        :key="index"
                      >
                        <p>{{ systeem.type }}</p>
                        <p>{{ useFilter.filter(systeem.vermogen, 'round2') }} kW</p>
                      </div>
                    </div>
                    <div class="basis-1/3" v-else>
                      <p>-</p>
                      <p></p>
                    </div>
                  </div>

                  <!-- Streefwaarden woningisolatie -->
                  <p class="text-l text-slate-500 my-2 font-bold">Standaardwaarde voor woningisolatie (indicatief)</p>
                  <div class="flex flex-row text-sm text-slate-500 mb-4">
                    <p class="basis-1/3"></p>
                    <p class="basis-1/3 font-bold">Huidig</p>
                    <p class="basis-1/3 font-bold">Nieuw</p>
                  </div>

                  <!-- woningtype -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Eistype</p>
                    <p class="basis-1/3">
                      {{ useFormulaHuidig20.result.data_total.streefwaarden_woningisolatie_woningtype }}
                      {{ useFormulaHuidig20.result.data_total.streefwaarden_woningisolatie_era }}
                    </p>
                    <p class="basis-1/3">
                      {{ useFormulaNieuw20.result.data_total.streefwaarden_woningisolatie_woningtype }}
                      {{ useFormulaNieuw20.result.data_total.streefwaarden_woningisolatie_era }}
                    </p>
                  </div>

                  <!-- Verliesoppervlakte -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Verliesoppervlakte (Als)</p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useFormulaHuidig20.result.data_total.streefwaarden_woningisolatie_als,
                          'round2'
                        )
                      }}
                      m2
                    </p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(useFormulaNieuw20.result.data_total.streefwaarden_woningisolatie_als, 'round2')
                      }}
                      m2
                    </p>
                  </div>

                  <!-- Gebruiksoppervlakte -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Gebruiksoppervlakte (Ag)</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaHuidig20.result.data_total.totaal_gebruiksoppervlakte, 'round2') }}
                      m2
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useFormulaNieuw20.result.data_total.totaal_gebruiksoppervlakte, 'round2') }}
                      m2
                    </p>
                  </div>

                  <!-- compactheid -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Compactheid (Als/Ag)</p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useFormulaHuidig20.result.data_total.streefwaarden_woningisolatie_compactheid,
                          'round2'
                        )
                      }}
                    </p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useFormulaNieuw20.result.data_total.streefwaarden_woningisolatie_compactheid,
                          'round2'
                        )
                      }}
                    </p>
                  </div>

                  <!-- netto warmtevraag -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Netto warmtevraag</p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useFormulaHuidig20.result.data_total.streefwaarden_woningisolatie_netto_warmtevraag,
                          'round'
                        )
                      }}
                      kWh/m2/jaar
                    </p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useFormulaNieuw20.result.data_total.streefwaarden_woningisolatie_netto_warmtevraag,
                          'round'
                        )
                      }}
                      kWh/m2/jaar
                    </p>
                  </div>

                  <!-- max netto warmtevraag -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Eis</p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useFormulaHuidig20.result.data_total.streefwaarden_woningisolatie_max_netto_warmtevraag,
                          'round'
                        )
                      }}
                      kWh/m2/jaar
                    </p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useFormulaNieuw20.result.data_total.streefwaarden_woningisolatie_max_netto_warmtevraag,
                          'round'
                        )
                      }}
                      kWh/m2/jaar
                    </p>
                  </div>

                  <!-- Voldoet -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Voldoet</p>
                    <p class="basis-1/3">
                      {{ useFormulaHuidig20.result.data_total.streefwaarden_woningisolatie_voldoet }}
                    </p>
                    <p class="basis-1/3">
                      {{ useFormulaNieuw20.result.data_total.streefwaarden_woningisolatie_voldoet }}
                    </p>
                  </div>

                  <p class="text-l text-slate-500 my-2 font-bold">Kosten</p>

                  <!-- overzicht energiekosten -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3"></p>
                    <p class="basis-1/3 font-bold">Huidig</p>
                    <p class="basis-1/3 font-bold">Nieuw</p>
                  </div>

                  <!-- kosten per maand eerste jaar -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Energiekosten per maand (jaar 1)</p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useMaatregelen.costs.value.energiekosten_per_maand_eerste_jaar_huidig,
                          'currencyRound'
                        )
                      }}
                    </p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useMaatregelen.costs.value.energiekosten_per_maand_eerste_jaar_nieuw,
                          'currencyRound'
                        )
                      }}
                    </p>
                  </div>

                  <!-- kosten per jaar eerste jaar -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Energiekosten per jaar (jaar 1)</p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useMaatregelen.costs.value.energiekosten_per_jaar_eerste_jaar_huidig,
                          'currencyRound'
                        )
                      }}
                    </p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(
                          useMaatregelen.costs.value.energiekosten_per_jaar_eerste_jaar_nieuw,
                          'currencyRound'
                        )
                      }}
                    </p>
                  </div>

                  <!-- kosten na 20 jaar -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Energiekosten na 20 jaar</p>
                    <p class="basis-1/3">
                      {{
                        useFilter.filter(useMaatregelen.costs.value.energiekosten_na_20_jaar_huidig, 'currencyRound')
                      }}
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useMaatregelen.costs.value.energiekosten_na_20_jaar_nieuw, 'currencyRound') }}
                    </p>
                  </div>

                  <!-- CO2 uitstoot per jaar -->
                  <div class="flex flex-row text-sm text-slate-500 mb-4">
                    <p class="basis-1/3">CO2-uitstoot per jaar</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useMaatregelen.costs.value.co2_uitstoot_per_jaar_huidig, 'round') }} kg CO2
                    </p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useMaatregelen.costs.value.co2_uitstoot_per_jaar_nieuw, 'round') }} kg CO2
                    </p>
                  </div>

                  <!-- terugverdientijd -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Netto investering</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useMaatregelen.costs.value.toaal_investering, 'currencyRound') }}
                    </p>
                    <p class="basis-1/3"></p>
                  </div>

                  <!-- co2 reductie na 20 jaar -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Co2-reductie na 20 jaar</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useMaatregelen.costs.value.co2_reductie_na_20_jaar, 'round') }} kg CO2
                    </p>
                    <p class="basis-1/3"></p>
                  </div>

                  <!-- Geldbesparing na 20 jaar -->
                  <div class="flex flex-row text-sm text-slate-500">
                    <p class="basis-1/3">Geldbesparing na 20 jaar</p>
                    <p class="basis-1/3">
                      {{ useFilter.filter(useMaatregelen.costs.value.geldbesparing_na_20_jaar, 'currencyRound') }}
                    </p>
                    <p class="basis-1/3"></p>
                  </div>

                  <!-- terugverdientijd -->
                  <div class="flex flex-row text-sm text-slate-500 mb-4">
                    <p class="basis-1/3">Terugverdientijd</p>
                    <p class="basis-1/3">{{ useMaatregelen.costs.value.terugverdientijd }} Jaar</p>
                    <p class="basis-1/3"></p>
                  </div>

                  <p class="text-l text-slate-500 my-2 font-bold">Financiering (annuïteitenfinanciering)</p>
                  <hypotheekBox :handler="useHypotheek" :maatregelenhandler="useMaatregelen" />

                  <div class="max-w-screen-lg mx-auto">
                    <vue-highcharts
                      class="mt-16"
                      v-if="!surveyDetails.loadHandler.states.get_records"
                      type="chart"
                      :options="useGrafieken.options_energiekosten"
                      :redrawOnUpdate="true"
                      :oneToOneUpdate="false"
                      :animateOnUpdate="true"
                      ref="root"
                    />

                    <vue-highcharts
                      class="mt-16"
                      v-if="!surveyDetails.loadHandler.states.get_records"
                      type="chart"
                      :options="useGrafieken.options_energiekosten_cumulatief"
                      :redrawOnUpdate="true"
                      :oneToOneUpdate="false"
                      :animateOnUpdate="true"
                      ref="root"
                    />

                    <vue-highcharts
                      class="mt-16"
                      v-if="!surveyDetails.loadHandler.states.get_records"
                      type="chart"
                      :options="useGrafieken.options_energiegebruik"
                      :redrawOnUpdate="true"
                      :oneToOneUpdate="false"
                      :animateOnUpdate="true"
                      ref="root"
                    />

                    <vue-highcharts
                      class="mt-16"
                      v-if="!surveyDetails.loadHandler.states.get_records"
                      type="chart"
                      :options="useGrafieken.options_co2"
                      :redrawOnUpdate="true"
                      :oneToOneUpdate="false"
                      :animateOnUpdate="true"
                      ref="root"
                    />
                  </div>

                  <div v-if="useHypotheek.errors.value.length > 0">
                    <p class="text-slate-500">Los de volgende problemen op om te kunnen exporteren</p>
                    <div class="" v-for="(value, key) in useHypotheek.errors.value" :key="key">
                      <p class="text-sm text-red-500">
                        {{ value.message }}
                      </p>
                    </div>
                  </div>
                </baseCard>
              </template>
            </baseTabs>
          </div>
        </div>
      </div>
    </div>

    <calculatorForm :handler="calculatorHandler" />
    <insulationForm :handler="useStandaardIsolatie" />

    <!-- confirmer -->
    <confimationModal :handler="confirm" />
    <!-- rolf export modal -->
    <baseModal
      v-if="exportModal && useContentGuard.canSee('admin:read')"
      title="Exporteer jaar"
      size="l"
      @close="() => (exportModal = false)"
    >
      <template v-slot>
        <div
          class="w-full"
          v-for="klimaatZone in useFormulaHuidig.result.data_total.klimaatzones"
          :key="klimaatZone.naam"
        >
          <baseButton @click="copyTableContents" class="mb-2">Kopieer tabel</baseButton>
          <table class="w-full">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Transmissie</th>
                <th>Infiltratie</th>
                <th>Ventilatie</th>
                <th>Zoninstraling</th>
                <th>Verwarming behoefte</th>
              </tr>
            </thead>
            <tbody ref="tableBody">
              <template v-for="(maand, naam) in klimaatZone.export_data" :key="maand">
                <tr v-for="(data, index) in maand" :key="index">
                  <td>{{ index + 1 }} {{ naam }}</td>
                  <td>{{ data.subtotaal_transmissie_kwh }}</td>
                  <td>{{ data.subtotaal_infiltratie_kwh }}</td>
                  <td>{{ data.subtotaal_ventilatie_kwh }}</td>
                  <td>{{ data.subtotaal_zoninstraling }}</td>
                  <td>
                    {{
                      data.subtotaal_transmissie_kwh +
                      data.subtotaal_infiltratie_kwh +
                      data.subtotaal_ventilatie_kwh +
                      data.subtotaal_zoninstraling
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import installatiesForm from '@/components/extended/installatiesForm.vue'
import klimaatZone from '@/components/extended/klimaatZone.vue'
import surveyDetailHandler from '@/use/surveyDetailHandler'
import tabHandler from '@/use/tabHandler'
import { useRoute } from 'vue-router'
import calculatorForm from '@/components/extended/calculatorForm.vue'
import insulationForm from '@/components/extended/insulationForm.vue'
import metricsBox from '@/components/extended/metricsBox.vue'
import resultsBox from '@/components/extended/resultsBox.vue'
import useCalculator from '@/use/calculatorHandler'
import calcualtorHandler2 from '@/use/calcualtorHandler2'
import errorBox from '@/components/extended/errorBox.vue'
import filterHandler from '@/use/filterHandler'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'
import Highcharts from 'highcharts'
import maatregelenBox from '@/components/extended/maatregelenBox.vue'
import hypotheekBox from '@/components/extended/hypotheekBox.vue'
import VueHighcharts from '@/use/highCharts'
import exportingInit from 'highcharts/modules/exporting'
import offlineExporting from 'highcharts/modules/offline-exporting'
import { version } from '../../package'
import { CalculatorIcon } from '@heroicons/vue/outline'
import standaardIsolatieHandler from '@/use/standaardIsolatieHandler'
import useTooltipHandler from '@/use/tooltipHandler'
import toastHandler from '@/use/toastHandler'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  setup() {
    const route = useRoute()
    const root = ref(null)
    const errorLevel = ref('info')
    const exportModal = ref(false)

    const useContentGuard = contentGuardHandler()

    exportingInit(Highcharts)
    offlineExporting(Highcharts)
    const useStandaardIsolatie = standaardIsolatieHandler()

    // filter
    const useFilter = filterHandler()

    // const useFormules = formuleHandler()

    // Survey detail handler
    const options = {
      id: route.params.objectId,
    }

    const surveyDetails = surveyDetailHandler(options)

    // confirmationhandler
    const confirm = confirmationHandler()

    // regular formulas
    const useFormulaHuidig = surveyDetails.useFormulaHuidig
    const useFormulaNieuw = surveyDetails.useFormulaNieuw

    // 20 formulas
    const useFormulaHuidig20 = surveyDetails.useFormulaHuidig20
    const useFormulaNieuw20 = surveyDetails.useFormulaNieuw20

    const useMaatregelen = surveyDetails.useMaatregelen
    const useHypotheek = surveyDetails.useHypotheek
    const useGrafieken = surveyDetails.useGrafieken

    const tabOptions = computed(() => {
      const basic = surveyDetails.surveyData.value.basic
      let tabs = []
      if (basic) {
        tabs = [
          { id: 'algemeen', label: 'Algemeen', active: true },
          { id: 'installaties_huidig', label: 'Installaties huidig', active: true },
          { id: 'klimaatzones_huidig', label: 'Isolatie huidig', active: true },
          { id: 'installaties_nieuw', label: 'Installaties nieuw', active: true },
          { id: 'klimaatzones_nieuw', label: 'Isolatie nieuw', active: true },
          {
            id: 'berekening',
            label: 'Berekening',
            active: false,
            disabled: computed(() => !surveyDetails.showBerekening.value),
          },
          {
            id: 'maatregelen',
            label: 'Maatregelen',
            active: true,
            disabled: computed(() => !surveyDetails.showBerekening.value),
          },
          {
            id: 'resultaat',
            label: 'Resultaat',
            active: true,
            disabled: computed(() => !surveyDetails.showBerekening.value),
          },
        ]
      } else {
        tabs = [
          { id: 'algemeen', label: 'Algemeen', active: true },
          { id: 'installaties_huidig', label: 'Installaties huidig', active: true },
          { id: 'klimaatzones_huidig', label: 'Klimaatzones huidig', active: true },
          { id: 'installaties_nieuw', label: 'Installaties nieuw', active: true },
          { id: 'klimaatzones_nieuw', label: 'Klimaatzones nieuw', active: true },
          {
            id: 'berekening',
            label: 'Berekening',
            active: true,
            disabled: computed(() => !surveyDetails.showBerekening.value),
          },
          {
            id: 'maatregelen',
            label: 'Maatregelen',
            active: true,
            disabled: computed(() => !surveyDetails.showBerekening.value),
          },
          {
            id: 'resultaat',
            label: 'Resultaat',
            active: true,
            disabled: computed(() => !surveyDetails.showBerekening.value),
          },
        ]
      }
      tabs.forEach((tab) => {
        tab.required = computed(() => {
          return surveyDetails.allErrors.value[tab.id]?.errors.length > 0
        })
      })
      return { tabs: tabs }
    })

    const tabs = tabHandler()

    const tabOptionsResultaat = {
      tabs: ['Resultaat', 'Exporteren'],
    }

    const tabsResultaat = tabHandler()

    // calculatorhandler
    const calculatorHandler = useCalculator()
    const usecalculator2 = calcualtorHandler2()

    const tooltipHandler = useTooltipHandler()

    onMounted(async () => {
      await surveyDetails.getRecord()
      tabs.create(tabOptions.value)
      tabsResultaat.create(tabOptionsResultaat)
    })

    function exportChart(options) {
      return new Promise(function (resolve) {
        const container = document.createElement('div')
        const chart = Highcharts.chart(container, options)
        let svg = chart.getSVG({ chart: { width: 605, height: 302 } })
        var canvas = document.createElement('canvas')
        canvas.width = 605
        canvas.height = 302
        var ctx = canvas.getContext('2d')

        var img = document.createElement('img')
        img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg))))
        img.onload = function () {
          ctx.drawImage(img, 0, 0)
          let imgsrc = canvas.toDataURL('image/png')
          resolve(imgsrc)
        }
      })
    }

    async function onExport() {
      // and export
      const energiekosten_options = useGrafieken.options_energiekosten.value
      const energiekosten_cum_options = useGrafieken.options_energiekosten_cumulatief.value
      const energieverbruik_options = useGrafieken.options_energiegebruik.value
      const co2uitstoot_options = useGrafieken.options_co2.value
      const energiekosten_src = await exportChart(energiekosten_options)
      const energiekosten_cum_src = await exportChart(energiekosten_cum_options)

      const energieverbruik_src = await exportChart(energieverbruik_options)
      const co2uitstoot_src = await exportChart(co2uitstoot_options)

      const grafieken = {
        energiekosten: energiekosten_src,
        energiekosten_cumulatief: energiekosten_cum_src,
        energieverbruik: energieverbruik_src,
        co2uitstoot: co2uitstoot_src,
      }

      const versionPayload = {
        frontend_version: version,
        frontend_hash: process.env.VUE_APP_FRONTEND_HASH,
      }

      const ok = await confirm.open({
        title: 'Rapport genereren',
        message: 'exportReport',
      })
      if (ok) {
        surveyDetails.editRecord().then(() => {
          surveyDetails.generateReport(useFormulaHuidig, useFormulaNieuw, grafieken, versionPayload)
        })
      }
    }

    async function onTabChange(changeInfo) {
      const { source } = changeInfo
      // volgensmij is tabindex als je surveydetails voor het eerst opent null
      if (source) {
        const formFinder = {
          algemeen: [surveyDetails.soortForm],
          installaties_huidig: [surveyDetails.installatieForm],
          klimaatzones_huidig: [surveyDetails.klimaatZonesForm],
          installaties_nieuw: [surveyDetails.installatieFormNieuw],
          klimaatzones_nieuw: [surveyDetails.klimaatZonesFormNieuw],
          maatregelen: [useMaatregelen],
          berekening: [],
          resultaat: [useHypotheek],
        }

        const forms = formFinder[source]
        let checker = []

        forms.forEach((form) => {
          checker.push(form.dataMonitor.changed(form.data.value))
        })

        if (checker.includes(true)) {
          const ok = await confirm.open({
            title: 'Let op',
            message: 'Gegevens zijn gewijzigd. Opslaan?',
          })
          if (ok) {
            surveyDetails.editRecord()
          }
        }
      }
    }

    function clickCalculator() {
      usecalculator2.open()
    }

    return {
      surveyDetails,
      tabs,
      tabsResultaat,
      calculatorHandler,
      useFormulaHuidig,
      useFormulaNieuw,
      useMaatregelen,
      useHypotheek,
      useFilter,
      confirm,
      onExport,
      root,
      useGrafieken,
      useFormulaHuidig20,
      useFormulaNieuw20,
      onTabChange,
      clickCalculator,
      useStandaardIsolatie,
      errorLevel,
      tooltipHandler,
      exportModal,
      useContentGuard,
    }
  },
  methods: {
    async copyTableContents() {
      const useToast = toastHandler()
      const tableBody = this.$refs.tableBody
      try {
        await navigator.clipboard.writeText(tableBody['0'].innerText)
        useToast.addToast('Tabel gekopieerd', 'success')
      } catch (err) {
        useToast.addToast('Kopiëren mislukt', 'danger')
      }
    },
  },
  components: {
    installatiesForm,
    klimaatZone,
    calculatorForm,
    metricsBox,
    resultsBox,
    maatregelenBox,
    hypotheekBox,
    VueHighcharts,
    errorBox,
    confimationModal,
    CalculatorIcon,
    insulationForm,
  },
}
</script>
