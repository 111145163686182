<template>
  <data-table v-if="users.length > 0" :data="users">
    <template #toolbar>
      <table-add-button @add="showAddModal = true" />
      <table-filters-button />
      <search-field />
    </template>

    <template #header>
      <table-header-cell field="name" label="Naam">Naam</table-header-cell>
      <table-header-cell field="email" label="E-mail">E-mail</table-header-cell>
      <table-header-cell field="actions" label="Acties" :filterable="false" :sortable="false">
        <div class="flex justify-center w-full">Acties</div>
      </table-header-cell>
    </template>

    <template #row="{ record }">
      <table-row-cell field="name">{{ record.name }}</table-row-cell>
      <table-row-cell field="email">{{ record.email }}</table-row-cell>
      <table-row-cell field="actions" class="flex flex-row justify-center">
        <table-delete-button @delete="deleteUser(record.id)" />
      </table-row-cell>
    </template>
  </data-table>

  <baseModal v-if="showAddModal" size="m" @close="closeModal" title="Add user">
    <form class="w-full flex flex-col">
      <baseInput v-model="name" label="Name" type="string" id="name" />
      <baseInput v-model="email" label="Email" type="email" id="email" />
      <baseInput v-model="password" label="Password" :password="true" type="password" id="password" />
      <baseSelectField v-model="selectedRole" :options="roleOptions" label="Role" zIndex="z-[1]" />
    </form>
    <template v-slot:buttons>
      <baseButton @action="addUser" class="mr-2">Add</baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import toastHandler from '@/use/toastHandler'

const useApi = apiHandler()

const useToast = toastHandler()
const showAddModal = ref(false)
const users = ref([])
const roles = ref([])

const name = ref('')
const email = ref('')
const password = ref('')
const selectedRole = ref(null)

const roleOptions = computed(() =>
  roles.value.map((role) => ({
    value: role.id,
    label: role.name,
  }))
)

onMounted(() => {
  getUsers()
  getRoles()
})

async function getUsers() {
  const { data } = await useApi.request('get', 'admin/users')
  users.value = data
}

async function getRoles() {
  const { data } = await useApi.request('get', 'admin/roles')
  roles.value = data
  if (data.length > 0) {
    selectedRole.value = data[0].id
  }
}

async function closeModal() {
  showAddModal.value = false
  name.value = ''
  email.value = ''
  password.value = ''
  selectedRole.value = roles.value.length > 0 ? roles.value[0].id : null
}

async function addUser() {
  try {
    const payload = {
      user: {
        name: name.value,
        email: email.value,
        password: password.value,
      },
      role_id: selectedRole.value,
    }
    await useApi.request('post', `admin/users`, payload)

    getUsers()
    closeModal()
  } catch (error) {
    useToast.addToast(`Error adding user:\n${error.response.data.detail.replaceAll('. ', '.\n')}`, 'danger', 10000)
    console.error('Error adding user', error)
  }
}

async function deleteUser(userId) {
  try {
    await useApi.request('delete', `admin/users/${userId}`)
    getUsers()
    useToast.addToast('User deleted successfully', 'success')
  } catch (error) {
    useToast.addToast(`Error deleting user:\n${error.response.data.detail}`, 'danger', 10000)
    console.error('Error deleting user', error)
  }
}
</script>
